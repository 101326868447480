import React from "react";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found"/>
    <Header
      title="404 Not Found"
    >
    </Header>
  </Layout>
);

export default NotFoundPage;
